.homeDiv{
    background-color: #f2f2f2;
    padding-bottom: 20px;
}

.picsDiv{
    width: 100%;
}

.carousel-container {
    position: relative;
    width: 100%;
    margin: auto;
    margin-top: 51px;
    overflow: hidden;
}

.carousel {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.slide {
    flex: 0 0 100%;
}

.picsDiv img {
    width: 100%;
    height: calc(100vh - 50px);
}

.picsDiv button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background-color: #3498db;
    color: #fff;
    padding: 10px 15px;
    cursor: pointer;
}

.prev {
    left: 0;
}

.next {
    right: 0;
}

.introduce{
    width: 100%;
    margin-top: 20px;
}
.introduce h1{
    text-align: center;
    color: orange;
}
.introduce_div{
    background-color: white;
    white-space: pre-wrap;
    padding: 2rem 8rem;
    width: 60%;
    margin: auto;
    border-radius: 5px;
}
.introduce_div p{
    margin: 0;
}

.contact{
    width: 100%;
    margin-top: 20px;
}
.contact h1{
    text-align: center;
    color: orange;
}
.contact_div{
    background-color: white;
    white-space: pre-wrap;
    padding: 2rem 8rem;
    width: 60%;
    margin: auto;
    border-radius: 5px;
}
.contact_div p{
    margin: 0;
}

.message{
    width: 10rem;
    margin: auto;
    margin-top: 20px;
    background-color: white;
    border: 1px solid orange;
    text-align: center;
    cursor: pointer;
    padding: 1rem;
    border-radius: 10rem;
    color: orange;
    font-weight: 500;
}

.appImg{
    white-space: pre-wrap;
    padding: 2rem 8rem;
    width: 50%;
    margin: auto;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
}

.appImg div{
    text-align: center;
    font-weight: bold;
}
