#navbar {
    background-color: #333;
    color: #fff;
    padding: 0 20px;
    height: 60px;
    line-height: 60px;
}

.navbarDiv{
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 0 auto;
}

.logo{
    display: flex;
    cursor: pointer;
    color: white;
    text-decoration: none;
    font-weight: bold;
}

.logo img{
    height: 50px!important;
    margin: auto;
    margin-right: 10px;
}

nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

nav li {
    margin-right: 15px;
}

nav a {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    transition: color 0.3s ease;
}

nav a:hover {
    color: #ffcc00;
}

/* login-modal.css */
.login-modal {
    max-width: 400px;
    margin: auto;
}

.login-modal .ant-input {
    width: 100%;
    margin-bottom: 10px;
}

.login-modal .code-input {
    display: flex;
}

.login-modal .code-input .ant-input {
    flex: 2;
    margin-right: 10px;
}

.login-modal .code-input .ant-btn {
    flex: 1;
    /*flex-shrink: 0;*/
}

.login-modal .ant-btn {
    width: 100%;
}
