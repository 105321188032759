.default-tab {
    width: 80px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    border: 1px solid #cccccc;
    border-radius: 5px
}

.created-tab {
    background-color: #E8F4FF;
    color: #1890FF;
    border: 1px solid #1890FF;
}

.complete-tab {
    background-color: #F0F9EB;
    color: #79C25C;
    border: 1px solid #79C25C;
}

.close-tab {
    background-color: #FFF8E6;
    color: #FFBA00;
    border: 1px solid #FFBA00;
}
