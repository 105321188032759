.cartDiv{
    height: calc(100vh - 60px);
    background-color: #f2f2f2;
}

.cartContent{
    width: 70%;
    margin: 0 auto;
    padding: 20px 0;
}

.cartTitle{
    display: flex;
    margin-bottom: 20px;
}

/* ShoppingCart.css */

.shopping-cart {
    padding: 20px;
    border: 1px solid #ddd;
    margin: 20px;
}

.ant-divider {
    margin: 12px 0;
}

.product-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.product-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    background-color: white;
    padding: 20px 20px;
    border-radius: 5px;
    width: calc(50% - 50px);
    margin-right: 10px;
    box-shadow: 0 1px 1px white; /* 添加阴影效果，可以根据需要调整 */
}

.product-info {
    margin-left: 20px;
    margin-right: auto;
}

.product-info .info-name {
    cursor: pointer;
}

.product-info p{
    color: #FF6000;
    font-weight: bold;
    font-size: 20px;
}

.product-info span{
    font-size: 12px;
}

.product-item img {
    width: 120px;
    height: auto; /* 自动调整高度以保持比例 */
    margin-left: 20px;
}

:where(.css-dev-only-do-not-override-gzal6t).ant-checkbox .ant-checkbox-inner{
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
}

.cartDel{
    color: red;
    cursor: pointer;
}

.delOptFor{
    margin-left: 20px;
    color: red;
    cursor: pointer;
    margin-right: auto;
}

.cartTitle .cartOrder {
    padding: 8px 20px;
    margin-left: 5px;
    background-color: #FF6000;
    color: #fff;
    cursor: pointer;
    border-radius: 25px;
    box-shadow: 0 2px 5px rgba(255, 96, 0, 0.6); /* 添加阴影效果，可以根据需要调整 */
}
