.productDiv{
    height: calc(100vh - 60px);
    background-color: #f2f2f2;
    overflow: auto;
}

.productDiv .centered-container{
    width: 70%;
    margin: auto;
    padding: 20px 0;
}

.product-card {
    width: 100%;
    /*padding: 10px;*/
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    cursor: pointer;
    background-color: white;
}

.product-image {
    width: 100%;
    max-height: 200px; /* 图片高度最大为150px */
    object-fit: cover; /* 保持宽高比，裁剪至适应容器，可能会裁剪部分图片 */
}

.imgDiv{
    height: 200px;
}

.product-content{
    padding: 10px;
}

.product-name{
    display: flex;
    align-items: center; /* 在交叉轴上居中 */
}

.filtrate {
    display: flex;
    align-items: center;
    height: 40px;
    border-bottom: 1px solid #DDD;
}

.content {
    display: flex;
    overflow: hidden;
    margin-left: 10px;
}

.secondRow {
    display: flex;
}

.type {
    margin-right: 8px;
    /*margin-bottom: 8px;*/
    padding: 8px;
    cursor: pointer;
    color: #005aa0;
}

.title{
    width: 100px;
    background-color: #fff;
    height: 40px;
    text-align: center;
    line-height: 40px;
}

.number{
    margin-left: 20px;
}
.number .ant-input-number{
    margin-right: 10px;
    margin-left: 10px;
}

.search-criteria{
    width: 100%;
    display: flex;
    margin-bottom: 20px;
}
.search-criteria div{
    padding: 10px;
    border: white 1px solid;
    background-color: white;
    margin-right: 10px;
}

.search-icon{
    margin-left: 10px;
    cursor: pointer;
    color: red;
}

.query{
    margin-left: 20px;
    color: #1677ff;
    cursor: pointer;
}

.reset{
    margin-left: 10px;
    color: red;
    cursor: pointer;
}

.search{
    width: 100%;
    margin-bottom: 20px;
}

.price {
    display: flex;
    align-items: flex-end; /* 垂直底部对齐 */
    color: red;
}

.price h2 {
    color: red; /* 不再使用!important，使用更具体的选择器来覆盖默认样式 */
    margin: 0; /* 可以根据需要调整右边距 */
}

.unit {
    font-size: 14px;
    margin-left: 5px; /* 可以根据需要调整左边距 */
}

.level{
    padding: 2px;
    border-radius: 3px;
    color: white;
    width: 30px;
}

.product-name-div {
    white-space: nowrap;       /* 禁止文本换行 */
    overflow: hidden;          /* 隐藏溢出的文本 */
    text-overflow: ellipsis;   /* 使用省略号 (...) 显示溢出的文本 */
    flex: 1;
}

.fcr{
    font-size: 12px;
    color: gray;
    margin: 2px 0;
    height: 32px;
}
