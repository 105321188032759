.orderDiv{
    width: 100%;
    height: calc(100vh - 60px);
    background-color: #f2f2f2;
}

.orderDetails{
    width: 70%;
    margin: 0 auto;
}

.order-list {
    display: flex;
    flex-wrap: wrap;
}

.order-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    background-color: white;
    padding: 20px 20px;
    border-radius: 5px;
    width: calc(50% - 50px);
    margin-right: 10px;
    box-shadow: 0 1px 1px white; /* 添加阴影效果，可以根据需要调整 */
}

.order-info {
    margin-left: 20px;
    margin-right: auto;
}

.order-info p{
    color: #FF6000;
    font-weight: bold;
    font-size: 20px;
}

.order-info span{
    font-size: 12px;
}

.order-item img {
    width: 120px;
    height: auto; /* 自动调整高度以保持比例 */
    margin-left: 20px;
}

:where(.css-dev-only-do-not-override-gzal6t).ant-checkbox .ant-checkbox-inner{
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
}

.orderInfo{
    padding-top: 20px;
    width: 400px;
}

.orderInfo-div{
    display: flex;
    margin-bottom: 20px;
}

.orderInfo-div div{
    width: 100px;
    text-align: right;
}

.orderDetails .cartOrder {
    padding: 8px 0;
    background-color: #FF6000;
    color: #fff;
    cursor: pointer;
    border-radius: 25px;
    box-shadow: 0 2px 5px rgba(255, 96, 0, 0.6); /* 添加阴影效果，可以根据需要调整 */
    width: 120px;
    text-align: center;
    margin-bottom: 20px;
}
