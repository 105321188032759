.detailsDiv{
    width: 100%;
    background-color: #f2f2f2;
    height: calc(100vh - 60px);
    overflow: auto;
}

.picsDiv{
    height: calc(100vh - 200px);
}

.staff{
    width: 3px;
    background-color: orange;
    height: 1rem;
    margin-right: 10px;
}

.infoTitle{
    font-weight: 500;
    display: flex;
    align-items: center; /* 在交叉轴上居中，即垂直居中 */
    font-size: 18px;
}

.price {
    display: flex;
    align-items: flex-end; /* 垂直底部对齐 */
    color: red;
}

.price h1 {
    color: red; /* 不再使用!important，使用更具体的选择器来覆盖默认样式 */
    margin: 0; /* 可以根据需要调整右边距 */
}

.unit {
    font-size: 14px;
    margin-left: 5px; /* 可以根据需要调整左边距 */
}

.detailHtml{
    margin-top: 10px;
}

.detailsDiv .centered-container{
    width: 70%;
    margin: auto;
    padding-bottom: 70px;
}

.centered-container .introduce{
    background-color: white;
    margin-top: 20px;
    padding: 20px;
    box-sizing: border-box; /* 设置 box-sizing 属性 */
    border-radius: 5px;
}

.individual-info{
    background-color: white;
    margin-top: 20px;
    padding: 20px;
    border-radius: 5px;
}

.individual-info .ant-row,.measure-info .ant-row {
    margin-left: 20px;
    margin-top: 10px;
}

.measure-info{
    background-color: white;
    margin-top: 20px;
    padding: 20px;
    border-radius: 5px;
}

.ant-col {
    margin-top: 15px; /* 调整列内部的上部间距 */
}

/* 最后一列去除右侧间距 */
.ant-col:last-child {
    margin-right: 0;
}

.bottom {
    height: 40px; /* 设置底部 div 的高度，可以根据需要调整 */
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f2f2f2; /* 设置底部背景色，可以根据需要调整 */
    padding: 10px; /* 设置底部内边距，可以根据需要调整 */
    position: fixed;
    bottom: 0;
    width: calc(70% - 20px);
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1); /* 添加底部阴影，可选 */
}

.bottom > div {
    display: flex;
    align-items: center;
}

.joinCart,
.order {
    padding: 8px 20px;
    margin-left: 5px;
    background-color: #FF6000;
    color: #fff;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(255, 96, 0, 0.6); /* 添加阴影效果，可以根据需要调整 */
}

.joinCart{
    border-radius: 25px 0 0 25px; /* 设置圆角半径，可以根据需要调整 */
}


.order{
    border-radius: 0 25px 25px 0; /* 设置圆角半径，可以根据需要调整 */
}

.joinCart.disabled,
.order.disabled {
    background-color: #808080; /* 使用浅灰色表示禁用状态 */
    cursor: not-allowed; /* 修改光标样式为禁止符号 */
    box-shadow: none; /* 添加阴影效果，可以根据需要调整 */
}



/* 购物车图标样式 */
.shopping-cart-icon {
    font-size: 30px; /* 设置购物车图标的大小，可以根据需要调整 */
    position: relative;
    cursor: pointer;
}

/* 红色圆点样式 */
.shopping-cart-icon::after {
    content: ''; /* 伪元素内容为空 */
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px; /* 圆点的直径，可以根据需要调整 */
    height: 20px; /* 圆点的直径，可以根据需要调整 */
    background-color: red; /* 红色背景 */
    border-radius: 50%; /* 圆形边框 */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; /* 白色文字 */
    font-size: 12px; /* 字号，可以根据需要调整 */
    font-weight: bold; /* 字体加粗 */
    content: attr(data-count); /* 设置显示的数字，通过 data-count 属性传递 */
}

/* 红点上显示数字的样式 */
.shopping-cart-icon[data-count='0']::after {
    display: none; /* 数字为 0 时隐藏 */
}

.order-modal-div{
    display: flex;
    margin-top: 20px;
}

.order-modal-div div{
    width: 100px;
    text-align: right;
}
